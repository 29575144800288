<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      @ok="handleCancel"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div>
        <div class="item cert_cover">
          <div><b>证件拍照：</b></div>

          <a target="_blank"
             v-if="record.idcard_front"
             :href="record.idcard_front.external_url">
            <img
                class="image"
                :src="record.idcard_front.external_url"
            />
          </a>

          <a target="_blank"
             v-if="record.idcard_opposite"
             :href="record.idcard_opposite.external_url">
            <img
                class="image"
                :src="record.idcard_opposite.external_url"
            >
          </a>
          <a target="_blank"
             v-if="record.cyzgz"
             :href="record.cyzgz.external_url">
            <img
                class="image"
                :src="record.cyzgz.external_url"
            >
          </a>
          <a target="_blank"
             v-if="record.jsz_front"
             :href="record.jsz_front.external_url">
            <img
                class="image"
                :src="record.jsz_front.external_url"
            >
          </a>
        </div>
        <div class="item">
          <div><b>基本信息：</b></div>
          <a-row>
            <a-col :span="8">职位：
              <a-tag color="blue" v-if="record.personnel_type===0">司机</a-tag>
              <a-tag color="orange" v-else>押运员</a-tag>
            </a-col>
            <a-col :span="8">入职状态：<a>{{ statusArray[record.entry_status] }}</a></a-col>
            <a-col :span="8">入职时间：<a>{{ record.entry_time }}</a></a-col>
            <a-col :span="8">手机号码：<a>{{ record.mobile }}</a></a-col>
          </a-row>
          <div></div>
        </div>
        <div class="item">
          <div><b>身份证信息：</b></div>
          <a-row>
            <a-col :span="8">身份证姓名：<a>{{ record.personnel_name }}</a></a-col>
            <a-col :span="8">身份证号码：<a>{{ record.idcard_number }}</a></a-col>
            <a-col :span="8">出生日期：<a>{{ record.birthdate }}</a></a-col>
            <a-col :span="8">身份证性别：<a>{{ record.gender == 0 ? '男' : '女' }}</a></a-col>
            <a-col :span="8">住址：<a>{{ record.idcard_zz }}</a></a-col>
          </a-row>
          <div></div>
        </div>
        <div class="item">
          <div><b>从业资格证信息：</b></div>
          <a-row>
            <a-col :span="8">姓名：<a>{{ record.cyzgz_xm }}</a></a-col>
            <a-col :span="8">性别：<a>{{ record.cyzgz_xb }}</a></a-col>
            <a-col :span="8">证号：<a>{{ record.cyzgz_zh }}</a></a-col>
            <a-col :span="8">有效期限：<a>{{ record.cyzgz_yxqx }}</a></a-col>
            <a-col :span="8">核发机关：<a>{{ record.cyzgz_hfjg }}</a></a-col>
            <a-col :span="8">从业资格类别：<a>{{ record.cyzgz_lb }}</a></a-col>
            <a-col :span="8">有效起始日期：<a>{{ record.cyzgz_yxqsrq }}</a></a-col>
          </a-row>
          <div></div>
        </div>
        <div class="item" v-if="record.personnel_type===0">
          <div><b>驾驶证信息：</b></div>
          <a-row>
            <a-col :span="8">驾驶证姓名：<a>{{ record.jsz_name }}</a></a-col>
            <a-col :span="8">驾驶证号：<a>{{ record.jsz_number }}</a></a-col>
            <a-col :span="8">准驾车型：<a>{{ record.jsz_zjcx }}</a></a-col>
            <a-col :span="8">有效期限：<a>{{ record.jsz_yxqx }}</a></a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>

import {UploadFile, UploadImage} from '@/components'

export default {
  components: {
    UploadFile,
    UploadImage
  },
  data() {
    return {
      // 标签页索引
      tabKey: 0,
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 10
      },
      // 输入框布局属性
      wrapperCol: {
        span: 14
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前记录
      record: {},

      statusArray: ['未入职', '已入职']
    }
  },
  methods: {
    /**
     * 显示对话框
     */
    open(item) {
      console.log(item)
      this.record = item
      this.title = '查看详情'
      this.visible = true
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
    },
  }
}
</script>
<style scoped lang="less">
.item {
  padding: 10px;
  border: 1px #e8e8e8 solid;
  border-radius: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}

.cert_cover {
  .image {
    height: 100px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px #e8e8e8 solid;
  }
}

</style>
