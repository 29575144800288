<template>
  <a-modal
      :title="title"
      :width="1370"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
      </div>
      <s-table
          ref="table"
          rowKey="askleave_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:500}"
      >
        <div slot="start_time" slot-scope="text, item">
          {{ item.start_time_str }} ~ {{ item.end_time_str }}
        </div>

        <div slot="status" slot-scope="text, item">
          <a-tag v-if="item.status==0">待审核</a-tag>
          <a-tag color="green" v-if="item.status==1">审核通过</a-tag>
          <template v-if="item.status==2">
            <a-tag color="red">
              审核驳回
            </a-tag>
            <br>
            <span style="color: red">{{ item.audit_text }}</span>
          </template>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <template v-if="item.status==0">
            <a v-action:askleave_audit
               @click="handleAuditPass(item,1)">审核通过</a>
            <a v-action:askleave_audit
               @click="handleAuditNoPass(item,2)">审核驳回</a>
          </template>
          <a v-action:askleave_edit @click="handleEdit(item)">编辑</a>
          <a-popconfirm
              v-action:askleave_delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
      <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
      <FillRejectText ref="FillRejectText" @handleSuccess="onAuditNoPass"/>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/personnel/askleave'
import {STable, FillRejectText} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";

export default {
  name: "ShowToolList",
  components: {SearchForm, FillRejectText, SaveForm, STable},
  data() {
    return {
      title: '请假记录',
      queryParam: {},
      visible: false,
      loading: false,
      isLoading: false,
      columns: [
        {
          title: '审核状态',
          dataIndex: 'status',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '请假期限',
          dataIndex: 'start_time',
          align: 'center',
          scopedSlots: {customRender: 'start_time'}
        },
        {
          title: '请假事由',
          dataIndex: 'reason',
          align: 'center',
        },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          align: 'center',
          width: 180,
        },
        {
          title: '审核人',
          dataIndex: 'store_user.real_name',
          align: 'center',
        },
        {
          title: '审核时间',
          dataIndex: 'audit_time',
          align: 'center',
          width: 180,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },
      currId: ''
    }
  },
  methods: {
    open(item) {
      this.queryParam.personnel_id = item.personnel_id
      this.title = item.personnel_name + ' - 请假记录'
      this.visible = true
      // this.$nextTick(() => {
      //   this.handleRefresh(true)
      // })
    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleAuditPass(item, val) {
      Api.audit({apiId: item['askleave_id'], form: {status: val}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    handleAuditNoPass(item, val) {
      this.currId = item.askleave_id
      this.$refs.FillRejectText.open()
    },

    onAuditNoPass(text) {
      Api.audit({apiId: this.currId, form: {status: 2, audit_text: text}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    handleDelete(item) {
      Api.deleted({apiId: item['askleave_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
