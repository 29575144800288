<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <a-button v-action:add type="primary" icon="plus" @click="handleAdd">入职员工</a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="personnel_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
    >
      <div slot="document_status" slot-scope="text, item">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <div style="margin-bottom: 5px">从业资格证：
              <a-tag color="green">正常</a-tag>
              <!--              <a-tag color="orange" v-if="item.document_status_list.ctxsz==1">即将过期</a-tag>-->
              <!--              <a-tag color="red" v-if="item.document_status_list.ctxsz==2">已过期</a-tag>-->
            </div>
            <div style="margin-bottom: 5px" v-if="item.personnel_type==0">驾驶证：
              <a-tag color="green">正常</a-tag>
              <!--              <a-tag color="orange" v-if="item.document_status_list.ctysz==1">即将过期</a-tag>-->
              <!--              <a-tag color="red" v-if="item.document_status_list.ctysz==2">已过期</a-tag>-->
            </div>
          </template>
          <a-tag color="green" v-if="text==0">正常</a-tag>
          <a-tag color="orange" v-if="text==1">即将过期</a-tag>
          <a-tag color="red" v-if="text==2">已过期</a-tag>
        </a-tooltip>
      </div>

      <div slot="vehicle" slot-scope="text, item">
        {{ item.vehicle ? item.vehicle.vehicle_name + ' - ' + item.vehicle.gccp : '' }}
      </div>
      <div slot="personnel_type" slot-scope="text, item">
        <a-tag color="blue" v-if="text===0">司机</a-tag>
        <a-tag color="orange" v-else>押运员</a-tag>
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag color="green" v-if="text===0">在职</a-tag>
        <a-tag color="red" v-if="text===1">离职</a-tag>
        <a-tag color="orange" v-if="text===2">请假中</a-tag>
      </div>

      <div slot="entry_status" slot-scope="text, item">
        <a-tag color="blue" v-if="text===1">已入职</a-tag>
        <a-tag v-else>未入职</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <a v-action:askleave @click="showAskleaveLog(item)">请假记录</a>
        <!--        <a v-action:edit @click="handleEdit(item)">编辑</a>-->
        <a v-action:details @click="handleDetails(item)">查看详情</a>

        <a-popconfirm
            v-action:depart
            title="确认将当前用户标记已离职？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDepart(item)"
        >
          <a href="#">标记已离职</a>
        </a-popconfirm>

        <!--        <a v-action:depart @click="handleDepart(item)">标记已离职</a>-->
        <!--        <a v-action:delete @click="handleDelete(item)">删除</a>-->
      </div>
    </s-table>
    <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
    <EntryModel ref="EntryModel" @handleSubmit="handleRefresh"/>
    <DetailsModel ref="DetailsModel" @handleSubmit="handleRefresh"/>
    <AskleaveLogModel ref="AskleaveLogModel" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/personnel'
import {STable} from '@/components'
import SaveForm from '@/views/personnel/modules/SaveForm.vue'
import SearchForm from '@/views/personnel/modules/SearchForm.vue'
import EntryModel from '@/views/personnel/modules/EntryModel'
import DetailsModel from '@/views/personnel/modules/DetailsModel.vue'
import AskleaveLogModel from './askleave/Index.vue'

export default {
  props: ['type'],
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm,
    EntryModel,
    DetailsModel,
    AskleaveLogModel
  },
  data() {
    return {
      // 查询参数
      queryParam: {
        entry_status: 1,
        personnel_type: this.type
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        // {
        //   title: '用户ID',
        //   dataIndex: 'personnel_id'
        // },
        {
          title: '证件状态',
          dataIndex: 'document_status',
          width: 80,
          scopedSlots: {customRender: 'document_status'}
        },
        {
          title: '职位',
          dataIndex: 'personnel_type',
          scopedSlots: {customRender: 'personnel_type'}
        },
        {
          title: '身份证姓名',
          dataIndex: 'personnel_name',
        },
        {
          title: '身份证号码',
          dataIndex: 'idcard_number',
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
        },

        {
          title: '驾驶车辆',
          dataIndex: 'vehicle',
          scopedSlots: {customRender: 'vehicle'}
        },
        // {
        //   title: '人员状态',
        //   dataIndex: 'status',
        //   scopedSlots: {customRender: 'status'}
        // },
        {
          title: '入职状态',
          dataIndex: 'entry_status',
          scopedSlots: {customRender: 'entry_status'}
        },

        {
          title: '入职时间',
          dataIndex: 'entry_time'
        },
        // {
        //   title: '注册时间',
        //   dataIndex: 'create_time'
        // },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {
    showAskleaveLog(item) {
      this.$refs.AskleaveLogModel.open(item)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.EntryModel.add()
    },

    /**
     * 查看详情
     */
    handleDetails(item) {
      this.$refs.DetailsModel.open(item)
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 标记已离职
     */
    handleDepart(item) {
      const app = this
      Api.depart({personnelId: item['personnel_id']})
          .then((result) => {
            app.$message.success(result.message, 1.5)
            app.handleRefresh()
          })

    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({personnelId: item['personnel_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
